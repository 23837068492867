/**
 * Adds the Google Maps script tag dynamically with a callback
 * @param callbackName - Name of the callback function
 * @returns Promise<void>
 */
export function loadGoogleMapsScript(callbackName = "initMap"): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      const hostname = window?.location?.hostname;
      console.log("Current hostname:", hostname);

      // Check if script is already loaded
      const existingScript = document.querySelector(
        `script[src*="maps.googleapis.com"], script[src*="maps/proxy"]`
      );
      if (existingScript) {
        console.log("Google Maps script is already loaded");
        resolve();
        return;
      }

      // Define the callback function
      window[callbackName] = () => {
        console.log("Google Maps callback executed successfully");
        resolve();
      };

      // Create a script tag to load the Google Maps API
      const script = document.createElement("script");

      // Determine the appropriate URL based on hostname
      if (/^.*localhost.*/.test(hostname)) {
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=visualization,places&language=en&callback=${callbackName}`;
      } else if (/^visops-dev.7-eleven.com/.test(hostname)) {
        script.src = `https://api.7ep-dev.7-eleven.com/fuels/pricing/fpcc-store-location-util/api/google/maps/proxy?callback=${callbackName}`;
      } else if (/^visops-test.7-eleven.com/.test(hostname)) {
        script.src = `https://api.7ep-test.7-eleven.com/fuels/pricing/fpcc-store-location-util/api/google/maps/proxy?callback=${callbackName}`;
      } else {
        script.src = `https://api.7ep.7-eleven.com/fuels/pricing/fpcc-store-location-util/api/google/maps/proxy?callback=${callbackName}`;
      }

      script.async = true;
      script.defer = true;

      // Set timeout for script loading
      const timeout = setTimeout(() => {
        console.error("Script loading timed out");
        reject(new Error("Script loading timeout"));
      }, 30000); // 30 seconds timeout

      // Handle script load success
      script.onload = () => {
        clearTimeout(timeout);
        console.log("Google Maps script loaded successfully");
      };

      // Handle script loading errors
      script.onerror = (error) => {
        clearTimeout(timeout);
        console.error("Script loading error:", error);
        reject(new Error("Failed to load Google Maps script"));
      };

      // Append the script tag to the document head
      document.head.appendChild(script);
    } catch (error) {
      console.error("Error in loadGoogleMapsScript:", error);
      reject(error);
    }
  });
}

// Type definitions
declare global {
  interface Window {
    [key: string]: any;
    google?: any;
  }
}
