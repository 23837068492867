import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import { loadGoogleMapsScript } from "./scripts/loadGoogleMapsScript";

async function initializeApp() {
  try {
    console.log("Initializing application...");

    // Wait for Google Maps to load
    await loadGoogleMapsScript();
    console.log("Google Maps script loaded successfully.");

    const routes = constructRoutes(microfrontendLayout);
    const applications = constructApplications({
      routes,
      loadApp({ name }) {
        return System.import(name);
      },
    });

    const layoutEngine = constructLayoutEngine({ routes, applications });
    applications.forEach(registerApplication);
    layoutEngine.activate();

    // Start applications only after Google Maps is loaded
    start();
    console.log("Applications started successfully.");
  } catch (error) {
    console.error("Failed to initialize application:", error);
    // Handle initialization error appropriately
  }
}

// Initialize the application
initializeApp();
